@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";

.paragraph__glaccountlist {
    padding-top: 2.2em;
    
    ul {
        list-style: none;
        padding-left: 0;
    }
}

.glaccount {
    padding-bottom: 1rem;

    &--child {
        margin-left: 1rem;
    }

    &__header {
        &--parent {
            font-weight: 600;
        }
    }

    &__vailidity {
        font-style: italic;
        font-weight: normal;
    }

    &__overview {
        margin: 0 0 0 1rem;

        &--parent {
            margin: 0 0 0 2rem;
        }
    }

    &-hide-switch {
        margin-bottom: 1rem;
    }
}